"use client";

import {createContext, ReactNode, useContext, useEffect} from "react";
import {User} from "../types/user";
import {useQuery} from "@apollo/client";
import {ME} from "../queries/user";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";

type AuthContextType = {
  user: User | null;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({children}: { children: ReactNode }) => {
  const {loading, error, data} = useQuery(ME);
  const navigate = useNavigate();
  const location = useLocation();
  let [searchParams] = useSearchParams();
  const user: User = data?.me;
  useEffect(() => {
    console.log("User: ", user, "location: ", location.pathname, "error", error, loading);
    if (!loading) {
      if (!user) {
        if (location.pathname.startsWith("/signin")) {
          navigate(`/signin`);
        } else {
          navigate(`/signin?to=${location.pathname}`);
        }
      } else {
        const to = searchParams.get("to");
        console.log("to: ", to)
        if (to) navigate(to);
      }
    }
  }, [user]);

  return (
    <AuthContext.Provider
      value={{user}}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};