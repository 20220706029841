import React, {useState} from 'react';
import Page from "./Page";
import {useQuery} from '@apollo/client';
import {SearchOutlined} from '@ant-design/icons';
import {Button, DatePicker, Form, GetProp, Input, Space, Table, TableColumnType, TableProps, TimeRangePickerProps, Typography} from 'antd';
import type {Dayjs} from 'dayjs';
import dayjs from 'dayjs';
import Highlighter from 'react-highlight-words';
import {Account, Filter, Journal, Source} from "../../types/transaction";
import type {ExpandableConfig} from "rc-table/lib/interface";
import {clientDateFormat, formatDate, formatNumber, getSumJournals, serverDateFormat} from "../../utils/transactionUtils";
import {GET_JOURNAL_BY_FILTER} from "../../queries/transaction";
import {TransactionDetailsInline} from "../inline/TransactionDetailsInline";
import {AccountSelect} from "../picker/AccountSelect";
import {SourcesSelect} from "../picker/SourcesSelect";
import {exportJournalsCSV} from "../../utils/export";
import {getOffset} from "../pagination/utils";
import {PaginationTotal} from "../pagination/PaginationTotal";

const {Column} = Table;
const {RangePicker} = DatePicker;

type DataIndex = keyof Journal;

const statusColors = new Map<string, string>([
  ["unassign", "default"],
  ["pending", "default"],
  ["booked", "processing"],
  ["processing", "warning"],
  ["accounted", "success"],
  ["error", "error"],
  ["closed", "success"]
]);

const rangePresets: TimeRangePickerProps['presets'] = [
  {label: 'Actual year', value: [dayjs().startOf('year'), dayjs()]},
  {label: `${dayjs().startOf('year').add(-1, 'y').year()} year`, value: [dayjs().startOf('year').add(-1, 'y'), dayjs().endOf('year').add(-1, 'y')]},
  {label: `${dayjs().startOf('year').add(-2, 'y').year()} year`, value: [dayjs().startOf('year').add(-2, 'y'), dayjs().endOf('year').add(-2, 'y')]},
  {label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()]},
  {label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()]},
];

declare type EventValue<DateType> = DateType | null;
declare type RangeValue<DateType> = [EventValue<DateType>, EventValue<DateType>] | null;
type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

const parseDateRange =
  (dates: RangeValue<Dayjs>, defaultFilter: Filter): Filter => (
    {
      startDate: dates?.[0]?.format(serverDateFormat) || defaultFilter.startDate,
      endDate: dates?.[1]?.format(serverDateFormat) || defaultFilter.endDate,
      accounts: defaultFilter.accounts,
      sources: defaultFilter.sources,
      statuses: [],
      vaults: []
    });

export const JournalsPage: React.FC = () => {

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [expandRowKeys, setExpandRowKeys] = useState<readonly React.Key[]>([]);

  const [filter, setFilter] = useState<Filter>(
    {
      startDate: dayjs().startOf('month').format(serverDateFormat),
      endDate: dayjs().endOf('month').format(serverDateFormat),
      text: undefined,
      accounts: [],
      sources: [],
      statuses: [],
      vaults: []
    });
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 50,
  });
  const [filterForm] = Form.useForm();


  const {loading, error, data} = useQuery(GET_JOURNAL_BY_FILTER, {
    variables: {filter: filter, paging: {limit: pagination.pageSize, offset: getOffset(pagination)}},
  });

  const sumJournals = data?.journalsPage?.aggregate.sum ?? 0;

  const getColumnSearchProps = (dataIndex: DataIndex): TableColumnType<Journal> => ({
    render: (text) =>
      filter.text ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[filter.text]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  });

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const expandable: ExpandableConfig<Journal> = {
    expandRowByClick: true,
    onExpand: (expanded, record) => setExpandRowKeys(expanded && record.id ? [record.id] : []),
    expandedRowRender: (record) => (record.transaction ? <TransactionDetailsInline transaction={record.transaction} filter={filter}/> : <div>none</div>),
    expandedRowKeys: expandRowKeys,
    rowExpandable: () => true,
  };

  const handleTableChange: TableProps<Journal>['onChange'] = (pagination, filters, sorter) => {
    setPagination(pagination);
  };

  const handleExport = () => {
    exportJournalsCSV(data?.journalsPage?.nodes);
  };

  const tableHeader =
    <Space style={{paddingBottom: "5px"}}>
      <Form
        onFinish={(values) =>
          setFilter((value) => {
            setPagination({current: 1, pageSize: pagination.pageSize})
            return {...parseDateRange(values.dates, value), text: values.text, accounts: values.accounts, sources: values.sources}
          })
        }
        layout="inline"
        form={filterForm}
        initialValues={{...filter, dates: [dayjs(filter.startDate, serverDateFormat), dayjs(filter.endDate, serverDateFormat)]}}
      >
        <Form.Item name="dates">
          <RangePicker
            presets={rangePresets}
            onChange={filterForm?.submit}
            format={clientDateFormat}
          />
        </Form.Item>
        <Form.Item name="accounts">
          <AccountSelect mode="multiple" placeholder="Filter by accounts" style={{width: 520}} onChange={filterForm?.submit}/>
        </Form.Item>
        <Form.Item name="sources">
          <SourcesSelect mode="multiple" placeholder="Filter by sources" style={{width: 420}} onChange={filterForm?.submit}/>
        </Form.Item>
        <Form.Item name="text">
          <Input style={{width: "250px"}} placeholder="Search by text" onPressEnter={filterForm?.submit}/>
        </Form.Item>
        <Button onClick={filterForm?.submit} style={{marginLeft: "-15px"}}><SearchOutlined/></Button>
      </Form>

      <Space>
        <Button onClick={handleExport}>Export</Button>
      </Space>
    </Space>

  return (
    <Page title="Journals" description="" divider={true}
    >
      {tableHeader}
      <Table<Journal>
        loading={loading}
        dataSource={data?.journalsPage?.nodes}
        rowKey="id"
        //rowSelection={rowSelection}
        pagination={
          {
            position: ["topRight", "bottomRight"],
            current: pagination?.current,
            defaultPageSize: 50,
            total: data?.journalsPage?.totalCount,
            pageSizeOptions: ["50", "100", "150"],
            showTotal: (total) => (
              <PaginationTotal total={total} entityName="journals"/>
            ),
          }
        }
        size="small"
        expandable={expandable}
        onChange={handleTableChange}
        summary={(pageData) => {
          const sum = getSumJournals(pageData);
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={1} colSpan={6}>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={2} align={"right"}>
                  <Typography.Text style={{color: sum > 0 ? "green" : "red"}}>{formatNumber(sum)}</Typography.Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} align={"right"}>
                  <Typography.Text style={{color: sumJournals > 0 ? "green" : "red"}}>{formatNumber(sumJournals)}</Typography.Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          )
        }
        }
      >
        <Column
          title="Id. Nr."
          dataIndex="id"
          key="id"
          width={100}
          {...getColumnSearchProps("id")}
          render={(value: string) => (
            <Typography.Text>{`FIN-${value}`}</Typography.Text>
          )}
        />
        <Column
          title="Date"
          dataIndex="valueDate"
          key="valueDate"
          width={100}
          render={(value: string) => (
            <Typography.Text>{formatDate(value)}</Typography.Text>
          )}
        />
        <Column
          title="Name"
          dataIndex="peerName"
          key="peerName"
          width={300}
          {...getColumnSearchProps("peerName")}
        />
        <Column
          title="Description"
          dataIndex="description"
          key="description"
          {...getColumnSearchProps("description")}
        />
        <Column
          title="Account"
          dataIndex="account"
          key="account"
          width={350}
          render={(value: Account) => (
            <Typography.Text>{value?.fullName || "Account is not defined"}</Typography.Text>
          )}
        />
        <Column
          title="Source"
          dataIndex="source"
          key="source"
          width={250}
          render={(value: Source) => (
            <Typography.Text>{value ? `${value.key} ${value.name}` : "Source is not defined"}</Typography.Text>
          )}
        />
        <Column
          title="Amount"
          dataIndex="amountValue"
          key="amountValue"
          width={100}
          className="align-right"
          render={(amount: number) => (
            <Typography.Text style={{color: amount > 0 ? "green" : "red"}}>{formatNumber(amount)}</Typography.Text>
          )}

        />
        <Column title="Currency" dataIndex="amountCurrency" key="amountCurrency" width={100}/>
      </Table>
    </Page>
  );
};