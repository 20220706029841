import React, {useState} from 'react';
import Page from "./Page";
import {useQuery} from '@apollo/client';
import {SearchOutlined} from '@ant-design/icons';
import {Button, DatePicker, Form, GetProp, Input, Space, Table, TableColumnType, TableProps, Tag, TimeRangePickerProps, Typography} from 'antd';
import type {Dayjs} from 'dayjs';
import dayjs from 'dayjs';
import Highlighter from 'react-highlight-words';
import {TransactionDetailsInline} from "../inline/TransactionDetailsInline";
import {Filter, Status, Transaction, Vault} from "../../types/transaction";
import type {ExpandableConfig} from "rc-table/lib/interface";
import {clientDateFormat, formatDate, formatNumber, getPeerName, serverDateFormat} from "../../utils/transactionUtils";
import {GET_TRANSACTIONS} from "../../queries/transaction";
import {PaginationTotal} from "../pagination/PaginationTotal";
import {getOffset} from "../pagination/utils";
import {statusColors} from "../../utils/statusColors";
import {StatusesSelect} from "../picker/StatusesSelect";
import {VaultsSelect} from "../picker/VaultsSelect";

const {Column} = Table;
const {RangePicker} = DatePicker;

type DataIndex = keyof Transaction;

const rangePresets: TimeRangePickerProps['presets'] = [
  {label: 'Actual year', value: [dayjs().startOf('year'), dayjs()]},
  {label: `${dayjs().startOf('year').add(-1, 'y').year()} year`, value: [dayjs().startOf('year').add(-1, 'y'), dayjs().endOf('year').add(-1, 'y')]},
  {label: `${dayjs().startOf('year').add(-2, 'y').year()} year`, value: [dayjs().startOf('year').add(-2, 'y'), dayjs().endOf('year').add(-2, 'y')]},
  {label: 'Last 30 Days', value: [dayjs().add(-30, 'd'), dayjs()]},
  {label: 'Last 90 Days', value: [dayjs().add(-90, 'd'), dayjs()]},
];

declare type EventValue<DateType> = DateType | null;
declare type RangeValue<DateType> = [EventValue<DateType>, EventValue<DateType>] | null;
type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

const parseDateRange =
  (dates: RangeValue<Dayjs>, defaultFilter: Filter): Filter => (
    {
      startDate: dates?.[0]?.format(serverDateFormat) || defaultFilter.startDate,
      endDate: dates?.[1]?.format(serverDateFormat) || defaultFilter.endDate,
      text: defaultFilter.text,
      accounts: [],
      sources: [],
      statuses: defaultFilter.statuses,
      vaults: []
    });

export const TransactionsPage: React.FC = () => {

  const [expandRowKeys, setExpandRowKeys] = useState<readonly React.Key[]>([]);
  const [filter, setFilter] = useState<Filter>(
    {
      startDate: dayjs().startOf('month').format(serverDateFormat),
      endDate: dayjs().endOf('month').format(serverDateFormat),
      text: undefined,
      accounts: [],
      sources: [],
      statuses: [],
      vaults: []
    });
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 50,
  });
  const [filterForm] = Form.useForm();

  const {loading, error, data} = useQuery(GET_TRANSACTIONS, {
    variables: {filter: filter, paging: {limit: pagination.pageSize, offset: getOffset(pagination)}},
    /*pollInterval: 30000*/
  });


  const getColumnSearchProps = (searchedColumn: DataIndex): TableColumnType<Transaction> => ({
    render: (text) =>
      filter.text ? (
        <Highlighter
          highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
          searchWords={[filter.text]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const expandable: ExpandableConfig<Transaction> = {
    expandRowByClick: true,
    onExpand: (expanded, record) => setExpandRowKeys(expanded ? [record.id] : []),
    expandedRowRender: (record) => <TransactionDetailsInline transaction={record} filter={filter}/>,
    expandedRowKeys: expandRowKeys,
    rowExpandable: (record: Transaction) => record.status.type !== 'Not Expandable',
  };

  const handleTableChange: TableProps<Transaction>['onChange'] = (pagination, filters, sorter) => {
    setPagination(pagination);
  };


  const tableHeader =
    <Space style={{paddingBottom: "5px"}}>
      <Form
        onFinish={(values) =>
          setFilter((value) => {
            setPagination({current: 1, pageSize: pagination.pageSize})
            return {...parseDateRange(values.dates, value), text: values.text, statuses: values.statuses, vaults: values.vaults}
          })
        }
        layout="inline"
        form={filterForm}
        initialValues={{...filter, dates: [dayjs(filter.startDate, serverDateFormat), dayjs(filter.endDate, serverDateFormat)]}}
      >
        <Form.Item name="dates">
          <RangePicker
            presets={rangePresets}
            onChange={filterForm?.submit}
            format={clientDateFormat}
          />
        </Form.Item>
        <Form.Item name="statuses">
          <StatusesSelect mode="multiple" placeholder="Filter by statuses" style={{width: 220}} onChange={filterForm?.submit}/>
        </Form.Item>
        <Form.Item name="vaults">
          <VaultsSelect mode="multiple" placeholder="Filter by vaults" style={{width: 220}} onChange={filterForm?.submit}/>
        </Form.Item>
        <Form.Item name="text">
          <Input style={{width: "250px"}} placeholder="Search by text" onPressEnter={filterForm?.submit}/>
        </Form.Item>
        <Button onClick={filterForm?.submit} style={{marginLeft: "-15px"}}><SearchOutlined/></Button>
      </Form>
    </Space>


  return (
    <Page title="Transactions" description="" divider={true}>
      {tableHeader}
      <Table<Transaction>
        loading={loading}
        dataSource={data?.transactionsPage?.nodes}
        rowKey="id"
        pagination={
          {
            position: ["topRight", "bottomRight"],
            current: pagination?.current,
            defaultPageSize: 50,
            total: data?.transactionsPage.totalCount,
            pageSizeOptions: ["50", "100", "150"],
            showTotal: (total) => (
              <PaginationTotal total={total} entityName="transactions"/>
            ),
          }
        }
        size="small"
        expandable={expandable}
        onChange={handleTableChange}
      >
        <Column
          title="Status"
          dataIndex="status"
          key="status"
          width={70}
          render={(status: Status) => (
            <Tag color={statusColors.get(status.name)} key={status.id}>
              {status.name.toUpperCase()}
            </Tag>
          )}
        />
        <Column
          title="Date"
          dataIndex="valueDate"
          key="valueDate"
          width={100}
          render={(value: string) => (
            <Typography.Text>{formatDate(value)}</Typography.Text>
          )}
        />
        <Column
          title="Name"
          dataIndex="creditorName"
          key="creditorName"
          width={350}
          {...getColumnSearchProps("creditorName")}
          render={(_: any, record: Transaction) => (
            <Highlighter
              highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
              searchWords={[filter.text ? filter.text : '']}
              autoEscape
              textToHighlight={getPeerName(record)}
            />
          )}
        />
        <Column
          title="Description"
          dataIndex="description"
          key="description"
          {...getColumnSearchProps("description")}
        />
        <Column
          title="Amount"
          dataIndex="amountValue"
          key="amountValue"
          width={100}
          className="align-right"
          render={(amount: number) => (
            <Typography.Text style={{color: amount > 0 ? "green" : "red"}}>{formatNumber(amount)}</Typography.Text>
          )}

        />
        <Column title="Currency" dataIndex="amountCurrency" key="amountCurrency"/>
        <Column
          title="Reference"
          dataIndex="endToEndId"
          key="endToEndId"
          {...getColumnSearchProps("endToEndId")}
        />
        <Column
          title="Vault"
          dataIndex="vault"
          key="vault"
          width={70}
          {...getColumnSearchProps("vault")}
          /*
                    onFilter={(value, record: Transaction) =>
                      (record?.vault.name
                        .toLowerCase()
                        .includes((value as string).toLowerCase()) || false)}
          */
          render={(vault: Vault) => (
            <Typography.Text>{vault.name}</Typography.Text>
          )}
        />
      </Table>
    </Page>
  );
};