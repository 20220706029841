import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, DatePicker, Form, GetRef, Input, InputNumber, InputRef, Skeleton, Space, Table, Typography} from 'antd';
import {useMutation, useQuery} from "@apollo/client";
//import "./styles/journals.css"
import {Account, Filter, Journal, JournalInput, Source, Transaction} from "../../types/transaction";
import {clientDateFormat, formatDate, formatNumber, getPeerName, getSumJournals, serverDateFormat, updateJournal} from "../../utils/transactionUtils";
import {ADJUST_TRANSACTION, GET_JOURNALS, GET_TRANSACTIONS, SET_JOURNALS} from "../../queries/transaction";
import {PickerRef} from "rc-picker/lib/interface";
import dayjs from "dayjs";
import {AccountSelect} from "../picker/AccountSelect";
import {SourcesSelect} from "../picker/SourcesSelect";
import {useAuth} from "../../context/AuthContextProvider";

type FormInstance<T> = GetRef<typeof Form<T>>;

type JournalsProps = { transaction: Transaction, filter: Filter };
type FormItemType = "input" | "date" | "number" | "accountSelect" | "sourceSelect";

const EditableContext = React.createContext<FormInstance<any> | null>(null);


interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({index, ...props}) => {
  const [form] = Form.useForm();
  return (
    <Form
      form={form}
      component={false}
    >
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Journal;
  record: Journal;
  handleSave: (record: Journal) => void;
  formItemType: FormItemType;
}

const EditableCell: React.FC<EditableCellProps> = ({
                                                     title,
                                                     editable,
                                                     children,
                                                     dataIndex,
                                                     record,
                                                     handleSave,
                                                     formItemType = "input",
                                                     ...restProps
                                                   }) => {
  const [editing, setEditing] = useState(false);
  const [pickerOpen, setPickerOpen] = useState(false);
  const elRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<InputRef>(null);
  const pickerRef = useRef<PickerRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
      pickerRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    if (formItemType === "date")
      form.setFieldsValue({[dataIndex]: dayjs((record[dataIndex] as string), serverDateFormat)});
    else if (formItemType === "accountSelect")
      form.setFieldsValue({[dataIndex]: (record[dataIndex] as Account)?.id});
    else
      form.setFieldsValue({[dataIndex]: record[dataIndex]});
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      console.log('values:', values);
      toggleEdit();
      if (formItemType === "date") {
        values[dataIndex] = values[dataIndex]?.format(serverDateFormat)
      }
      const toSave = {
        ...record,
        ...values,
        accountId: (values.account?.id || values.account || record.account?.id),
        account: undefined,
        sourceId: (values.source?.id || values.source || record.source?.id),
        source: undefined
      };
      console.log("toSave:", toSave, values, record)
      handleSave(toSave);

    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{margin: 0}}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        {formItemType === "input" && <Input ref={inputRef} onPressEnter={save} onBlur={save}/>}
        {formItemType === "date" && <DatePicker ref={pickerRef} allowClear={false} onBlur={() => !pickerOpen && save()} onChange={save} onOpenChange={setPickerOpen} format={clientDateFormat}/>}
        {formItemType === "number" && <InputNumber stringMode decimalSeparator="," ref={elRef} onPressEnter={save} onBlur={save}/>}
        {formItemType === "accountSelect" && <AccountSelect onBlur={save} defaultOpen/>}
        {formItemType === "sourceSelect" && <SourcesSelect onBlur={save} defaultOpen/>}
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{paddingRight: 24, minWidth: "30px"}} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];


type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;


const INTERN_ACCOUNT_ID = "63067aad-a1dc-4acb-912b-ea34f63793e6"; //todo get from configuration
const SCHULDEN_ACCOUNT_ID = "d15f7722-872d-40b5-93df-ff780c2a32e1"; //todo get from configuration

export const JournalsInline: React.FC<JournalsProps> = ({transaction, filter}) => {
  const {user} = useAuth();
  const {loading, error, data} = useQuery(GET_JOURNALS, {
    variables: {transactionId: transaction.id},
  });
  const [createOrUpdateJournal] = useMutation(SET_JOURNALS, {
    update(cache, {data}) {
      //console.log("cache", cache)
      //console.log("cache-update: ", data)
      const existing: any = cache.readQuery({query: GET_JOURNALS, variables: {transactionId: transaction.id}});
      //console.log("cache-update, existing: ", existing)
      cache.writeQuery({
        query: GET_JOURNALS,
        variables: {transactionId: transaction.id},
        data: {journals: updateJournal(existing.journals, data!.journal)}
      });
    },
    refetchQueries: [
      {
        query: GET_TRANSACTIONS,
        variables: {filter: filter, paging: {limit: 100, offset: 0}}
      }
    ]
  });

  const [adjustTransaction] = useMutation(ADJUST_TRANSACTION, {
    refetchQueries: [
      {
        query: GET_JOURNALS,
        variables: {transactionId: transaction.id}
      },
      {
        query: GET_TRANSACTIONS,
        variables: {filter: filter, paging: {limit: 100, offset: 0}}
      }
    ]
  });

  const sumJournals = getSumJournals(data?.journals);

  const handleDelete = (id: number) => {
  };

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string; formItemType?: FormItemType })[] = [
    {
      dataIndex: 'id',
      title: 'Id. Nr.',
      width: 100,
      render: (value: string) => (
        <Typography.Text>{`FIN-${value}`}</Typography.Text>
      )
    },
    {
      dataIndex: 'valueDate',
      title: 'Value Date',
      width: 130,
      editable: true,
      formItemType: "date",
      render: (value: string) => (
        <Typography.Text>{formatDate(value)}</Typography.Text>
      )
    },
    {
      dataIndex: 'peerName',
      title: 'Peer Name',
      editable: true,
      width: 200,
      render: (value: string) => (
        <Typography.Text>{value || "Empty"}</Typography.Text>
      ),
    },
    {
      dataIndex: 'description',
      title: 'Description',
      editable: true,
      render: (value: string) => (
        <Typography.Text>{value || "Empty"}</Typography.Text>
      ),
    },
    {
      dataIndex: 'account',
      title: 'Account',
      editable: true,
      formItemType: "accountSelect",
      render: (value: Account) => (
        <Typography.Text>{value?.fullName || "Please set Account"}</Typography.Text>
      ),
      width: 400
    },
    {
      dataIndex: 'source',
      title: 'Source',
      editable: true,
      formItemType: "sourceSelect",
      render: (value: Source, record: any, index) => (
        <Typography.Text>{value ? `${value.key} ${value.name}` : (record.account?.id !== INTERN_ACCOUNT_ID ? "Please set Source" : "")}</Typography.Text>
      ),
      width: 200
    },

    {
      dataIndex: 'amountValue',
      title: 'Value',
      width: 100,
      render: (amount: number) => (
        <Typography.Text style={{color: amount > 0 ? "green" : "red"}}>{formatNumber(amount)}</Typography.Text>
      ),
      editable: true,
      formItemType: "number",
    },
    {
      dataIndex: 'amountCurrency',
      title: 'Currency',
      width: 80,
      editable: true,
    },
    /*
            {
                title: 'operation',
                dataIndex: 'operation',
                render: (_, record: { key: React.Key }) =>
                    dataSource.length >= 1 ? (
                        <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
                            <a>Delete</a>
                        </Popconfirm>
                    ) : null,
            },
    */
  ];

  const handleAdd = () => {
    const newData: JournalInput = {
      peerName: getPeerName(transaction),
      description: transaction.description,
      valueDate: transaction.bookingDate,
      amountValue: transaction.amountValue - sumJournals,
      amountCurrency: 'EUR',
      transactionId: transaction.id,
    };
    createOrUpdateJournal({variables: {journal: newData}})
  };

  const handleSave = (row: JournalInput) => {
    const journal = {...row, transactionId: transaction.id, __typename: undefined};
    console.log("save: ", journal);
    createOrUpdateJournal({variables: {journal: journal}})
  };

  const handleAuto = () => {
    adjustTransaction({variables: {id: transaction.id}})
  }

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Journal) => ({
        record,
        editable: col.editable && (col.dataIndex !== "source" || (record.account?.id !== INTERN_ACCOUNT_ID && record.account?.id !== SCHULDEN_ACCOUNT_ID)) && user?.roles.includes("ROLE_ADMIN"),
        dataIndex: col.dataIndex,
        title: col.title,
        formItemType: col.formItemType,
        handleSave,
      }),
    };
  });

  return (
    <Skeleton loading={loading}>
      <Table
        size="small"
        components={components}
        rowKey="id"
        rowClassName={() => 'editable-row'}
        bordered
        pagination={{hideOnSinglePage: true, position: ["bottomRight"], defaultPageSize: 50}}
        dataSource={data?.journals}
        columns={columns as ColumnTypes}
        title={_ => {
          return (
            <Space>
              <Button onClick={handleAdd} type={(transaction.amountValue > 0 && sumJournals < transaction.amountValue) || (transaction.amountValue < 0 && sumJournals > transaction.amountValue) ? "primary" : "default"}>
                Add a journal
              </Button>
              <Button onClick={handleAuto}>
                Auto adjust
              </Button>
            </Space>
          );
        }
        }
        summary={(pageData) => (
          <>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
              <Table.Summary.Cell index={1} colSpan={5}>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2}>
                <Typography.Text style={{color: sumJournals > 0 ? "green" : "red"}}>{formatNumber(sumJournals)}</Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3}>
                <Typography.Text>{formatNumber(sumJournals - transaction.amountValue)}</Typography.Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </>
        )
        }
      />
    </Skeleton>
  );
};