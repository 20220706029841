import React from 'react';
import {Select, SelectProps, Tag} from 'antd';
import {useQuery} from "@apollo/client";
import {GET_SOURCES, GET_STATUSES} from "../../queries/transaction";
import {Source} from "../../types/transaction";
import {statusColors} from "../../utils/statusColors";

export interface StatusesSelectProps<ValueType = string>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
}

type TagRender = SelectProps['tagRender'];

const filterOption = (input: string, option?: { label: string; value: string }) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


const tagRender: TagRender = (props) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const key = label ? label as string: "unassign";
  return (
    <Tag
      color={statusColors.get(key)}
      key={value}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginInlineEnd: 4 }}
    >
      {key.toUpperCase()}
    </Tag>
  );
};


export const StatusesSelect = <ValueType extends { key?: string; label: string; value: string | number } = any, >({...props}: StatusesSelectProps<ValueType>) => {
  const {loading, error, data} = useQuery(GET_STATUSES);

  return (
    <Select<any, { label: string; value: string }>
      mode={props.mode}
      showSearch
      autoFocus
      defaultOpen={props.defaultOpen}
      defaultValue={props.value}
      placeholder={props.placeholder}
      placement="bottomRight"
      defaultActiveFirstOption={false}
      suffixIcon={null}
      optionFilterProp="children"
      filterOption={filterOption}
      notFoundContent={null}
      tagRender={tagRender}
      {...props}
      options={(data?.statuses || []).map((d: Source) => ({
        value: d.id,
        label: `${d.name}`,
      }))}
    />
  );

};

