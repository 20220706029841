import React from "react";

import {SettingOutlined} from "@ant-design/icons";
import {Button, Popover, Typography} from "antd";
import {CustomAvatar} from "./CustomAvatar";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../context/AuthContextProvider";

const LOGOUT_URL = `${process.env.REACT_APP_GATEWAY_URL || 'https://desc.team'}/logout`

export const CurrentUser = () => {
  const [opened, setOpened] = React.useState(false);
  const navigate = useNavigate();
  const {user} = useAuth();

  async function logout() {
    try {
      const response = await fetch(LOGOUT_URL, {
        method: "POST",
      });
      if (!response.ok) {
        throw new Error(`Response status: ${response.status}`);
      }
      navigate("/signin");
    } catch (error: any) {
      console.error(error.message);
    }
  }

  const content = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography.Text
        strong
        style={{
          padding: "12px 20px",
        }}
      >
        {user?.name}
      </Typography.Text>
      <div
        style={{
          borderTop: "1px solid #d9d9d9",
          padding: "4px",
          display: "flex",
          flexDirection: "column",
          gap: "4px",
        }}
      >
        <Button
          style={{textAlign: "left"}}
          icon={<SettingOutlined/>}
          type="text"
          block
          onClick={() => setOpened(true)}
        >
          Account settings
        </Button>
      </div>
      <div
        style={{
          borderTop: "1px solid #d9d9d9",
          padding: "4px",
          display: "flex",
          flexDirection: "column",
          gap: "4px",
        }}
      >
        <Button
          style={{textAlign: "left"}}
          icon={<SettingOutlined/>}
          type="text"
          block
          onClick={logout}
        >
          Logout
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
        <div style={{display: "flex", flexDirection: "column", cursor: "pointer"}}>
          <Popover
            placement="bottomRight"
            content={content}
            trigger="click"
            overlayInnerStyle={{padding: 0}}
            overlayStyle={{zIndex: 999}}
          >

            <CustomAvatar
              name={user?.name}
              src={user?.avatarUrl}
              size="default"
              style={{cursor: "pointer"}}
            />
          </Popover>
        </div>
      </div>
      {/*
      {user && (
        <AccountSettings
          opened={opened}
          setOpened={setOpened}
          userId={user.id}
        />
      )}
*/}
    </>
  );
};
