import React, {createContext, useState} from 'react';
import {ConfigProvider, theme} from 'antd';
import {LayoutComponent} from "./components/Layout";
import client from "./apolloClient";
import {ApolloProvider} from "@apollo/client";
import {AuthProvider} from "./context/AuthContextProvider";


export const ThemeContext = createContext({});

const App: React.FC = () => {
    let themeSettings;
    try {
        themeSettings = JSON.parse(localStorage.getItem("themeSettings")!);
    } catch {
        themeSettings = {};
    }

    return (
        <ThemeContext.Provider value={useState(themeSettings)}>
            <ConfigProvider
              theme={{
                  algorithm: theme.defaultAlgorithm,
                  token: {
                      colorPrimary: '#B3DF72',
                  },
                  components: {
                      Layout: {
                          bodyBg: "#ffffff"
                      },
                      Menu: {
                          //itemBg: '#F5F5F5',
                          itemSelectedColor: "#304953",
                          subMenuItemBg: "#ffffff",
                          itemSelectedBg: '#B3DF72',
                          itemActiveBg: '#B3DF72',
                          itemHoverBg: '#EFFFD6'
                      }
                  }
              }}
            >
                <ApolloProvider client={client}>
                  <AuthProvider>
                    <LayoutComponent/>
                  </AuthProvider>
                </ApolloProvider>
            </ConfigProvider>
        </ThemeContext.Provider>
    );
};

export default App;
